import { createTheme } from '@material-ui/core'
import { colors } from '../style'

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: colors.yellowOrange,
    },
  },
  typography: {
    fontFamily: ['Quicksand'],
  },
})

export default theme
