import React, { useReducer } from 'react'

export const StateContext = React.createContext()
export const DispatchContext = React.createContext()

const initailState = {
  theme: 'dark',
  language: 'pl',
  menuOpen: false,
  isScrollTop: true,
  selectedOption: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_THEME':
      return {
        ...state,
        theme: state.theme === 'dark' ? 'light' : 'dark',
      }
    case 'TOGGLE_LANGUAGE':
      return {
        ...state,
        language: state.language === 'pl' ? 'en' : 'pl',
      }
    case 'SET_LANGUAGE':
      return { ...state, language: action.payload }
    case 'SET_SCROLL_TOP':
      return { ...state, isScrollTop: action.payload }
    case 'TOGGLE_MENU':
      return { ...state, menuOpen: !state.menuOpen }
    case 'SET_MENU':
      return { ...state, menuOpen: action.payload }
    case 'SET_SELECTED_SERVICE':
      return { ...state, selectedOption: action.payload }
    default:
      throw new Error('Incorrect Action')
  }
}

const ContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initailState)
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {props.children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export default ContextProvider
